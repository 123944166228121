import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Android = () => {
	return (
		<Layout>
			<SEO title="Installing on an Android device" />
			<h1>Installing this as an app on your Android device</h1>
			<p>
				If you find this useful, you can easily add it as an app to your phone.
				This will allow you to access it easily as well as offline.
			</p>
			<ol>
				<li>
					On your Android device, open this site on Chrome (if not already
					there).
				</li>
				<li>Click on the &apos;3 dots&apos; icon on the top right.</li>
				<li>Tap Add to home screen.</li>
			</ol>
		</Layout>
	)
}

export default Android
